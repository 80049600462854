/* info-notice
---------------------------------------------------------- */
.info-notice {
  visibility: hidden;
  position: fixed;
  z-index: $info-notice;
  top: 0;
  left: calc(50% - 320px / 2);
  width: 100%;
  max-width: 320px;
  transform: translateY(-100%);
  transition: all 0.5s ease-out;

  &.is-show {
    visibility: visible;
    transform: translateY(0);
  }

  &__inner {
    position: relative;
    width: 100%;
    padding: 12px 20px;
    border: 6px solid $color-red;
    border-top: 0;
    border-radius: 0 0 30px 30px;
    background-color: $white;
  }

  &__close {
    position: absolute;
    z-index: $info-notice-close;
    top: 10px;
    right: 15px;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background-color: $color-red;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 13px;
      height: 13px;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      background: image-url("common/ico_close_white.svg") center no-repeat;
      background-size: 100%;
    }

    &:hover {
      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  &__date,
  &__detail {
    max-width: 178px;
    margin: 0 auto;
    color: $color-red;
    font-family: $family;
    font-size: $fix-font-large;
    font-weight: bold;
    text-align: center;
  }

  &__detail {
    margin-top: 6px;
  }
}
