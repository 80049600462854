/* ------------------------------
  form
------------------------------ */
.form-main {
  padding-bottom: rem(45);
}

.form-wrapper {
  margin: 0 rem(40);
}

.form {
  position: relative;
  box-sizing: border-box;
  max-width: 1280px;
  margin: 0 auto;
  padding: 60px rem(100);
  overflow: hidden;
  border-radius: 10px;
  background: image-url("common/bg_form.png") 50% 0 / 76px 76px;

  &__inner {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }

  &__section {
    margin-top: 65px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__heading01 {
    font-size: $font-heading02;
    font-weight: $bold;
    line-height: $line4;
    text-align: center;
  }

  &__heading02 {
    font-size: 22px;
    font-weight: $bold;
    line-height: $line4;
    text-align: center;
  }

  &__text {
    margin-top: 40px;
    text-align: center;
  }
}

.form-title {
  position: relative;
  padding: rem(32) 0 rem(21);
  color: $white;
  font-weight: $bold;
  text-align: center;

  img {
    width: auto;
    height: rem(31);
  }

  span {
    display: block;
    margin-top: rem(10);
    font-size: $font-base;
  }

  //タイトル青背景
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: image-url("common/bg_form_title.png") no-repeat center center / contain;
  }
}

.form-table-wrapper {
  margin-top: 50px;

  &:first-child {
    margin-top: 0;
  }
}

.form-table {
  $t: &;
  width: 100%;
  margin-top: 50px;

  &:first-child {
    margin-top: 0;
  }

  &__title {
    position: relative;
    width: 220px;
    padding: 40px 30px 0 0;
    font-size: $font-heading04;
    font-weight: $bold;
    text-align: left;

    #{$t} tr:first-child & {
      padding-top: 0;
    }

    span {
      font-size: $font-small;
    }
  }

  &__data {
    padding-top: 40px;
    vertical-align: middle;

    #{$t} tr:first-child & {
      padding-top: 0;
    }
  }

  &__note {
    display: block;
    margin-top: 10px;
    font-size: $font-small;
  }
}

//エラーメッセージ
.form-error-text {
  margin-top: 10px;
  color: $red900;
  font-size: $font-small;
  font-weight: $bold;
}

//必須タグ
.form-required {
  display: inline-block;
  margin: 5px 0 0 10px;
  padding: 0 8px;
  border-radius: 5px;
  background: $indigo300;
  color: $white;
  font-size: $font-small;
  font-weight: $bold;
  line-height: $line6;
  vertical-align: top;
}

// 選択肢横並び
.form-select-list {
  display: flex;
  flex-wrap: wrap;
  margin: -25px 0 0 -35px;

  &__item {
    margin: 25px 0 0 35px;
  }
}

//利用規約
.form-consent {
  margin-top: 50px;

  &__box {
    overflow: hidden;
    border: 3px solid $black900;
    border-radius: 20px;
    background: $white;
  }

  &__inner {
    padding: 20px 27px 30px;
  }

  &__title {
    padding: 15px 27px;
    background: $yellow500;
    font-weight: $bold;
    line-height: $line4;
    text-align: center;
  }

  &__link {
    color: $blue600;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__check {
    margin-top: 30px;
    text-align: center;
  }
}

.form-message-box {
  margin-top: 30px;
  padding: 30px;
  border-radius: 10px;
  background: $white;
  box-shadow: 3px 3px 10px rgba($black900, 0.1);

  &--far {
    margin-top: 45px;
  }

  &:first-child {
    margin-top: 0;
  }
}

//青点付きリスト
.form-dot-list {
  &__item {
    position: relative;
    margin-top: 15px;
    padding-left: 16px;
    line-height: $line4;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $blue600;
    }
  }
}

//横並びフィールド
.form-field {
  display: flex;
  align-items: center;
  gap: 10px;
}
