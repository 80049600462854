/* ------------------------------
  button
------------------------------ */
//通常ボタン（影あり線あり）
@mixin btn-base($w: 260px, $pad: 10px 20px) {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: $w;
  max-width: 100%;
  padding: $pad;
  border: 2px solid $black900;
  border-radius: 30px; //2行まで丸く
  box-shadow: 3px 3px 0 $black900;
  font-size: $fix-font-small;
  font-weight: $bold;
  line-height: $line2;
  text-align: center;

  &.is-disabled {
    border-color: $black400 !important;
    opacity: 0.8;
    background-color: $black100 !important;
    box-shadow: 3px 3px 0 $black400;
    color: $black400;
    pointer-events: none;
  }
}

//ミニボタン（影無し線のみ）
@mixin btn-base-mini($pad: 8px 11px 7px) {
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: $pad;
  transition: 0.2s;
  border-width: 2px;
  border-style: solid;
  border-radius: 40px; //3行まで丸く
  font-size: $fix-font-small;
  font-weight: $bold;
  line-height: $line2;
  text-align: center;

  &.is-disabled {
    border-color: $black400 !important;
    opacity: 0.8;
    background-color: $black100 !important;
    color: $black400;
    pointer-events: none;
  }
}

//基本（白・黒線）
.button {
  @include btn-base();
  border-color: $black900;
  background: $white;
  color: $black900;
}

//第1ボタン（黄・黒線）
.button-primary {
  @include btn-base();
  border-color: $black900;
  background: $yellow500;
  color: $black900;
}

//第1ボタン play（黄・黒線）
.button-play {
  @include btn-base;
  border-color: $black900;
  background: $yellow500;
  color: $black900;

  img {
    width: auto;
    height: 22px;
  }
}

//第2ボタン（黒・白線）
.button-secondary {
  @include btn-base();
  border-color: $white;
  background: $black900;
  color: $white;
}

//第3ボタン（青・黒線）
.button-tertiary {
  @include btn-base();
  border-color: $black900;
  background: $blue600;
  color: $white;
}

//サブ(グレー・黒線)
.button-sub {
  @include btn-base();
  border-color: $black900;
  background: $black100;
  color: $black900;
}

//第5ボタン ミニ(白・黒線)
.button-mini {
  @include btn-base-mini();
  border-color: $black900;
  background: $white;
  color: $black900;

  &:hover {
    color: $black900;
  }
}

// ----- オプション ----- //

//ボタン小
.button-small {
  width: auto;
}

//サウンドボタン（第3ボタン）
.button-sound {
  $t: &;
  @include btn-base($w: 120px);
  border-color: $black900;
  background: $blue600;
  color: $white;

  span {
    flex: 1 1 auto;
    height: 15px;
    margin: 0 10px 0 5px;
    color: transparent;
  }

  &::after {
    content: "";
    width: 26px;
    height: 25px;
  }

  &--on {
    &::after {
      background: image-url("common/ico_sound_on.svg") 50% 50% / auto 100% no-repeat;
    }
    span {
      background: image-url("common/text_on_white.svg") 50% 50% / auto 100% no-repeat;
    }
  }

  &--off {
    &::after {
      background: image-url("common/ico_sound_off.svg") 50% 50% / auto 100% no-repeat;
    }
    span {
      background: image-url("common/text_off_white.svg") 50% 50% / auto 100% no-repeat;
    }
  }
}

// ----- Button BOX ----- //
.button-box {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }

  &--closer {
    margin-top: 10px;
  }

  &__button {
    display: flex; //2行以上で揃う
    min-width: 0;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

//ボタン3つ以上（縦並び）
.button-box-many {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  &__button {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
