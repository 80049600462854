/* ------------------------------
  footer
------------------------------ */
.footer-share {
  position: relative;
  z-index: z(footer-share);
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  background: image-url("common/bg_triangle_blue.png") 0 0 / 112px 100px;

  &__text {
    color: $white;
    font-size: 14px;
    font-weight: $bold;
    text-align: center;
  }
}

.footer {
  position: relative;
  z-index: z(footer);
}

.footer-share-list {
  $c: &;
  display: flex;
  justify-content: center;
  margin-top: 15px;

  &__link {
    display: block;
    width: 50px;
    height: 50px;
    margin-left: 20px;
    padding: 0;
    transition: $trans;
    border: 0;
    background: none;

    &:first-child {
      margin-left: 0;
    }

    img {
      width: 100%;
    }
  }

  &--question-complete {
    margin-top: 5px;

    #{$c}__link {
      width: 40px;
      height: 40px;
    }
  }
}

.footer-official-twitter {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  &__button {
    display: flex;
    align-items: center;
    padding: 11px 15px;
    border-radius: 200px;
    background: $white;
    color: $blue600;
    font-size: $fix-font-base;
    font-weight: $bold;
    line-height: $line2;

    &::before {
      content: "";
      width: 22px;
      height: 22px;
      margin-right: 5px;
      background: image-url("common/logo_twitter02.svg") center / contain no-repeat;
    }
  }
}

.footer-corporate {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 10px;
  background: $black900;
  color: $white;

  &__logo {
    order: 1;
    width: 162px;
    height: 30px;
    margin-top: 25px;
    background: image-url("common/logo_corporate.png") 0 0 / auto 100% no-repeat;
    color: transparent;
  }
}

.footer-corporate-nav {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -15px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 15px 8px 0;
    line-height: $line1;

    &::before {
      content: "";
      width: 0;
      height: 0;
      margin-right: 5px;
      border-width: 4px 0 4px 6px;
      border-style: solid;
      border-color: transparent transparent transparent $yellow500;
    }
  }

  &__link {
    color: $white;
    font-size: 12px;
  }
}

.footer-legal {
  flex: 0 0 auto;
  order: 1;
  margin-top: 20px;
  font-size: 12px;
  line-height: $line4;
  text-align: center;

  &__text {
    a {
      padding: 0 2px;
      color: $yellow500;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.footer-pin {
  display: flex;
  position: fixed;
  z-index: z(footer);
  right: 145px;
  bottom: 10px;
  left: 16px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  pointer-events: none;

  >.copyright {
    margin-top: 10px;
    color: $white;
    font-size: $fix-font-xxsmall;
    font-weight: $bold;
    letter-spacing: -0.05em;
    line-height: $line2;

    &:first-child {
      margin-top: 0;
    }
  }

  &--right {
    right: 16px;
    left: unset;
  }
}

.footer-esrb {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  pointer-events: auto;

  a {
    display: block;
    height: 80px;
  }

  img {
    height: 100%;
  }
}

@keyframes banner-animation {
  0% {
    transform: scale(1);
  }

  35% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  65% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.footer-election-banner {
  display: block;
  width: 140px;
  animation: banner-animation 1.5s linear infinite;
  pointer-events: auto;

  img {
    width: 100%;
  }
}

.footer-retro-banner {
  display: block;
  width: 120px;
  animation: banner-animation 1.5s linear infinite;
  pointer-events: auto;

  img {
    width: 100%;
  }
}

.footer-museum-banner {
  display: block;
  width: 120px;
  animation: banner-animation 1.5s linear infinite;
  pointer-events: auto;

  img {
    width: 100%;
  }
}
