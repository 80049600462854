//フォントファミリー
$family: sans-serif;
$family-chinese: "SourceSans3";

//フォントサイズ
$font-xsmall: rem(10);
$font-small: rem(12);
$font-base: rem(16);
$font-large: rem(18);

//フォントサイズfix
$fix-font-xxsmall: 10px;
$fix-font-xsmall: 12px;
$fix-font-small: 14px;
$fix-font-base: 16px;
$fix-font-large: 18px;

//見出しフォントサイズ
$font-heading01: rem(30);
$font-heading02: rem(26);
$font-heading03: rem(22);
$font-heading04: rem(20);

//ボタン・入力要素フォントサイズ
$font-button: rem(16);
$font-form: 16px;

//フォントウェイト
$normal: 400;
$bold: 700;

//行間
$line1: 1;
$line2: 1.25;
$line3: 1.34;
$line4: 1.5;
$line5: 1.67;
$line6: 1.75;
$line7: 2;

//デザインベース
$base-width: 390;

//アニメーション
$trans: 0.3s ease;
