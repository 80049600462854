/* ------------------------------
  footer
------------------------------ */
.footer-share {
  display: flex;
  position: relative;
  z-index: z(footer-share);
  align-items: center;
  justify-content: center;
  padding: rem(40) rem(30);
  background: image-url("common/bg_triangle_blue.png") 0 0 / 112px 100px;

  &__text {
    margin-right: 15px;
    color: $white;
    font-weight: $bold;
  }
}

.footer {
  position: relative;
  z-index: z(footer);
}

.footer-share-list {
  display: flex;

  &__link {
    display: block;
    width: 40px;
    height: 40px;
    margin-left: 15px;
    padding: 0;
    transition: $trans;
    border: 0;
    background: none;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      transform: scale(1.25);
    }

    img {
      width: 100%;
    }
  }
}

.footer-official-twitter {
  position: relative;
  margin-left: 20px;
  padding-left: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: 30px;
    transform: translateY(-50%);
    border-radius: 10px;
    background: $white;
  }

  &__button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    transition: $trans;
    border-radius: 200px;
    background: $white;
    color: $blue600;
    font-weight: $bold;
    line-height: $line2;

    &::before {
      content: "";
      width: 20px;
      height: 20px;
      margin-right: 8px;
      background: image-url("common/logo_twitter02.svg") center / contain no-repeat;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.footer-corporate {
  display: flex;
  align-items: center;
  padding: 15px rem(30);
  background: $black900;
  color: $white;

  &__logo {
    flex: 0 0 auto;
    width: 134px;
    height: 25px;
    background: image-url("common/logo_corporate.png") 0 0 / auto 100% no-repeat;
    color: transparent;

    &:hover {
      color: transparent;
    }
  }
}

.footer-corporate-nav {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: rem(2) 0 0 rem(20);
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 2px rem(15) 2px 0;
    line-height: $line1;

    &::before {
      content: "";
      width: 0;
      height: 0;
      margin-right: 5px;
      border-width: 4px 0 4px 6px;
      border-style: solid;
      border-color: transparent transparent transparent $yellow500;
    }
  }

  &__link {
    color: $white;
    font-size: $font-xsmall;

    &:hover {
      color: $yellow500;
    }
  }
}

.footer-legal {
  flex: 0 0 auto;
  margin-left: auto;
  font-size: 12px;
  line-height: $line4;
  text-align: right;

  &__text {
    a {
      padding: 0 2px;
      color: $yellow500;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.footer-pin {
  z-index: z(footer);

  > .copyright {
    position: fixed;
    bottom: 12px;
    left: 20px;
    color: $white;
    font-size: $font-xsmall;
    font-weight: $bold;
    line-height: $line6;
  }
}

.footer-esrb {
  position: absolute;
  top: 50%;
  left: rem(30);
  transform: translateY(-50%);
  transition: $trans;

  a {
    display: block;
    width: rem(130);
    height: rem(80);

    &:hover {
      transform: scale(1.1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@keyframes banner-animation {
  0% {
    transform: scale(1);
  }

  35% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  65% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes banner-animation-hover {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.footer-election-banner {
  display: block;
  position: fixed;
  bottom: 40px;
  left: 20px;
  width: 190px;
  transition: $trans;
  animation: banner-animation 1.5s linear infinite;

  &:hover {
    transform: scale(1.1);
    animation: banner-animation-hover 0.2s linear;
  }

  img {
    width: 100%;
  }
}

.footer-retro-banner {
  display: block;
  z-index: z(footer);
  position: fixed;
  bottom: 40px;
  left: 20px;
  width: 190px;
  transition: $trans;
  animation: banner-animation 1.5s linear infinite;

  &:hover {
    transform: scale(1.1);
    animation: banner-animation-hover 0.2s linear;
  }

  img {
    width: 100%;
  }
}

.footer-museum-banner {
  display: block;
  z-index: z(footer);
  position: fixed;
  bottom: 40px;
  right: 20px;
  width: 190px;
  transition: $trans;
  animation: banner-animation 1.5s linear infinite;

  &:hover {
    transform: scale(1.1);
    animation: banner-animation-hover 0.2s linear;
  }

  img {
    width: 100%;
  }
}
