/* ------------------------------
  share
------------------------------ */
//コピー完了（headerに設置）
.share-copy-message {
  display: none;
  position: fixed;
  z-index: z(share-copy-message);
  top: 50%;
  left: 0;
  width: 100%;
  padding: rem(40) 0;
  transform: translateY(-50%);
  background: rgba($black900, 0.7);
  color: $white;
  font-size: 20px;
  font-weight: $bold;
  text-align: center;
}

//シェアリンク　タイトル
.share-title {
  font-size: $fix-font-small;
  font-weight: $bold;
  text-align: center;

  img {
    height: 23px;
  }

  &__sub {
    display: block;
    margin-top: 6px;
  }
}

// snsリンクリスト
.share-list {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  .news-detail-footer-share & {
    margin-top: 10px;
  }

  &__item {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    border: 0;
    background: transparent;
    color: $black900;

    img {
      width: 45px;
      height: 45px;
    }
  }

  &__name {
    display: none;
  }
}

// Twitterアカウントリンク
.share-twitter-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  &__text {
    box-sizing: border-box;
    width: 100%;
    padding: 25px 20px 35px;
    border-radius: 5px;
    background: $indigo50;
    font-size: $fix-font-small;
    font-weight: $bold;
    text-align: center;
  }

  &__button {
    margin-top: -25px;
  }
}

// TOP系 固定シェアボタン
.share-button-box {
  position: fixed;
  display: flex;
  z-index: z(share-button);
  right: 10px;
  bottom: 10px;
}

.share-button {
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }

  &__button {
    @include btn-base($w: auto, $pad: 15px);
    border-radius: 50%;
    border-color: $blue600;
    background: $white;
    box-shadow: 3px 3px 0 $blue600;
    color: $blue600;

    span {
      display: none;
    }

    &:hover {
      color: $blue600;
    }

    &::before {
      content: "";
      width: 18px;
      height: 18px;
      background: image-url("common/ico_share_blue.svg") center / contain no-repeat;
    }

    &--twitter {
      &::before {
        width: 20px;
        height: 20px;
        background-image: image-url("common/logo_twitter02.svg");
      }
    }
  }
}

//現在のページをシェア
.share-current-page {
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }

  &__textarea {
    margin-top: 30px;
  }
}
