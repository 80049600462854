/* ------------------------------
  remodal-custom
------------------------------ */
.remodal-overlay {
  z-index: z(remodal-overlay);
  background: rgba($black900, 0.65);

  //ミュージアム タイトル選択
  &.remodal-museum-title {
    background: none;
  }

  //ミュージアム年齢確認
  &.remodal-museum-age {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: rgba($black900, 0.85);
  }
}

.remodal-wrapper {
  z-index: z(remodal-wrapper);
  padding: 20px 20px 0;
  pointer-events: auto;

  //ミュージアム タイトル選択・詳細
  &.remodal-museum-title {
    padding: 0;
    overflow: hidden;
    text-align: right;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  //会長の部屋 名言
  &.remodal-room-famous-quote {
    padding: 0;
    overflow: hidden;
    text-align: left;
  }

  //ゲーム詳細 キーバインド
  &.remodal-game-key-bind {
    padding: 0;
    overflow: hidden;
  }
}

.remodal {
  z-index: z(remodal);
  width: auto;
  margin-bottom: 20px;
  padding: 25px;
  border: $black900 3px solid;
  border-radius: 15px;
  pointer-events: auto;

  //ボタンフロー付き
  &--flow {
    padding: 0;
  }

  //ゲーム詳細 キーバインド
  &.remodal-game-key-bind {
    display: inline-flex;
    flex-direction: column;
    width: 95%;
    max-width: none;
    max-height: 95vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  //ゲーム詳細 カートリッジ
  &.remodal-game-cartridge {
    width: 90%;
    max-width: none;
    padding: rem(50) rem(40);
  }

  //ミュージアム タイトル選択
  &.remodal-museum-title {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
    border-radius: 0;
    background: none;
    pointer-events: none;

    &.remodal-is-closing {
      animation-name: remodal-closing-museum-keyframes;
    }
  }

  //会長の部屋 名言
  &.remodal-room-famous-quote {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
    border-radius: 0;
    background: $black900;
  }

  // 超選挙中間発表
  &.remodal-middle-results {
    padding: 0;
  }

  &__body-flow {
    padding: 25px;
  }
}

.remodal-close {
  display: flex;
  z-index: z(remodal-close);
  top: -13px;
  right: -13px;
  left: auto;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: $trans;
  border: $black900 3px solid;
  border-radius: 40px;
  background: $blue600;
  pointer-events: auto;

  //ミュージアム タイトル選択・詳細
  .remodal-museum-title & {
    top: 15px;
    right: 15px;
  }

  //会長の部屋 名言
  .remodal-room-famous-quote & {
    top: rem(20);
    right: rem(20);
  }

  &::before {
    content: "";
    position: static;
    width: 15px;
    height: 15px;
    background: image-url("common/ico_close_white.svg") 0 0 / 100% 100% no-repeat;
  }

  &:hover {
    transform: scale(1.1);
    border-color: $white;
    background-color: $black900;
  }
}


//モーダル タイトル
.remodal-title {
  font-size: $fix-font-small;
  font-weight: $bold;
  text-align: center;

  img {
    height: 23px;
  }

  &__sub {
    display: block;
    margin-top: 6px;
  }
}

.remodal-text {
  $t: &;
  margin-top: 20px;
  font-size: $fix-font-small;
  font-weight: $bold;
  text-align: center;

  &+& {
    margin-top: 10px;
  }

  &--error {
    color: $red900;
  }
}

.remodal-button-box {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 15px 20px;
  background: $blue600;

  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  &__item {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@keyframes remodal-closing-museum-keyframes {
  from {
    transform: scale(1);

    opacity: 1;
  }

  to {
    transform: scale(1.05);

    opacity: 0;

    filter: blur(0);
  }
}
