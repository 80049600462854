// color
$white: #fff;

$logo-blue: #15359e;
$logo-yellow: #fdd400;

$black900: #000;    //primary
$black800: #262626;
$black700: #434343;
$black600: #555;
$black500: #7b7b7b;
$black400: #9d9d9d;
$black300: #c4c4c4;
$black200: #d9d9d9;
$black100: #e9e9e9;
$black50:  #f5f5f5;

$indigo900: #2d3442;
$indigo800: #40495a;
$indigo700: #505c70;
$indigo600: #617088;
$indigo500: #6f7e99;
$indigo400: #8491a9;
$indigo300: #9aa5bb;
$indigo200: #b6bfd0; //primary
$indigo100: #d1d9e5;
$indigo50:  #eceff8;

$violet900: #473e9a;
$violet800: #5451af;
$violet700: #5c5bbc;
$violet600: #5c5bbc;
$violet500: #6c70d4;
$violet400: #8087db;
$violet300: #989fe2;
$violet200: #b5bbea; //primary
$violet100: #d2d6f2;
$violet50:  #edeffa;

$blue900: #000f9f;
$blue800: #1528b1;
$blue700: #2134bd;
$blue600: #15359e; //primary
$blue500: #3147d4;
$blue400: #5564dc;
$blue300: #7581e2;
$blue200: #9ea4ea;
$blue100: #c5c8f3;
$blue50:  #e8e9fa;

$yellow900: #f97000;
$yellow800: #fb9c00;
$yellow700: #fcb600;
$yellow600: #fece00;
$yellow500: #ffe100; //primary
$yellow400: #fee43d;
$yellow300: #ffea64;
$yellow200: #fff091;
$yellow100: #fff6bd;
$yellow50:  #fffce4;

$red900: #c40404; //primary
$red800: #d21d16;
$red700: #df261e;
$red600: #f13224;
$red500: #ff3d22;
$red400: #fb4f43;
$red300: #f1716b;
$red200: #f89994;
$red100: #ffcdd0;
$red50:  #ffebed;


$color-red: #cb0000;
$color-davy-grey: #585858;
$color-chinese-white: #e0e0e0;

//loader
$loader-main:#ebf7ff;

//select2カラー
$select2-blue-pale: #e7effa;
$select2-blue: #4c9bea;

//ニュースペーパーカラー
$news-yellow-pale:#fffde5;
$news-yellow:#fff9ba;
$news-orange-pale:#fff2dd;
$news-orange:#ffe2b5;
$news_blue:#c9f1f8;
$news-light-blue-pale:#edfcff;
$news-light-blue: #c9f1f8;
$news-blue-pale: #e6f1ff;
$news-blue: #d5e6fa;

//ヒストリーカラー
$history-blue1: #6487eb;
$history-blue2: #dae7f7;
$history-blue3: #dfeaf9;
$history-white: #f8f9fd;
$history-yellow1: #ebdc6a;
$history-yellow2: #ead958;
$history-gray: #f3f3f3;

//超選挙 質問回答カラー
$question-light-blue1: #8cc2f2;
$question-light-blue2: #a8ebff;
$question-light-blue3: #4dc6fc;
$question-light-blue4: #4c9bea;
$question-light-blue5: #ccedff;
$question-light-blue6: #95caff;
$question-blue1: #2142af;
$question-blue2: #001a71;
$question-cyan: #aff;
$question-violet: #6c6780;

//超選挙 チケットカラー
$ticket-blue-pale: #e7effa;

//超選挙 当選者入力カラー
$winner-red: #9a0332;

//グッズファクトリーカラー
$goods-factory-beige: #faf6df;
$goods-factory-light-blue: #e7effa;
$goods-factory-blue: #407bff;
$goods-factory-blue-pale: #8badff;
$goods-factory-green: #67dd79;
$goods-factory-red: #ff6145;

// z-index
$base-locked: 1;

$z-map: (
  museum-main,
  footer-share,
  footer,
  share-button,
  header-back-button,
  header-overlay,
  header,
  header__nav,
  header__box,
  museum-detail-info,
  popup,
  remodal-overlay,
  remodal-wrapper,
  remodal,
  remodal-close,
  share-copy-message,
  loader
);
