/* ------------------------------
  横向き非対応 メッセージ
------------------------------ */
.landscape {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background: $white image-url("common/bg_form.png") 50% 0 / 76px 76px;

  &.is-active {
    display: flex;
  }

  &__image {
    width: 190px;

    img {
      width: 100%;
    }
  }

  &__message {
    margin-left: 15px;
    font-size: 20px;
    font-weight: $bold;
  }
}
