/* ------------------------------
  TOP
------------------------------ */
.main-top * {
  box-sizing: border-box;
}

#canvas {
  canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
  }
}
