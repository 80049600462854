$viewport-width: 390;
$z-index-cloud: 1;
$z-index-object: 2;
$z-index-rathalos: 2;
$z-index-airplane: 3;
$z-index-truck: 1;
$z-index-content: 10;

.loading-top {
  display: flex;
  position: fixed;
  z-index: $modal + 1;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $logo-blue;

  &__logo {
    display: block;
    position: absolute;
    width: 180px;
    height: auto;
    animation: loading-logo 0.3s linear 1s forwards;
    opacity: 0;
  }
}

.landing-top * {
  box-sizing: border-box;
}

.landing-top {
  position: fixed;
  z-index: $modal;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #feeb53;

  $this: &;

  &--ready {
    visibility: visible;
  }

  &__wrapper {
    display: flex;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    min-height: 530px;
    background-image: image-url("top/landing/bg_landing_sp.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;

    img {
      display: block;
      width: 100%;
      max-width: none;
      height: auto;
      max-height: none;
    }
  }

  &__cloud {
    position: absolute;
    z-index: $z-index-cloud;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    animation: object-move 27.6s linear infinite;

    &--first {
      width: vw(197);
      margin-top: percent(-23, $viewport-width);
      animation-delay: -9.52s;
    }

    &--second {
      width: vw(194);
      margin-top: percent(62, $viewport-width);
      animation-delay: -20.04s;
    }

    &--third {
      width: vw(242);
      margin-top: percent(62, $viewport-width);
      animation-delay: -2.64s;
    }
  }

  &__object {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    animation: object-move 17.39s linear infinite;

    &--first {
      z-index: $z-index-object;
      width: vw(115);
      max-width: 180px;
      margin-top: percent(30, $viewport-width);
      animation-delay: -0.55s;
    }

    &--second {
      z-index: $z-index-object;
      top: initial;
      bottom: 0;
      width: vw(150);
      max-width: 220px;
      margin-bottom: percent(150, $viewport-width);
      animation-delay: -6.502s;
      animation-direction: reverse;

      &::before {
        content: "";
        display: block;
        padding-top: 58.11%;
        animation: object-flying 4s linear infinite;
        background: image-url("top/landing/img_kobun.svg") 50% 0 / contain no-repeat;
      }
    }

    &--rathalos {
      z-index: $z-index-rathalos;
      top: initial;
      bottom: 0;
      width: vw(170);
      margin-bottom: percent(134, $viewport-width);
      animation-delay: -6.502s;
      animation-direction: reverse;

      &::before {
        content: "";
        display: block;
        padding-top: 58.11%;
        animation: rathalos-buru 4s linear infinite;
        background: image-url("top/landing/img_rathalos_01.svg") 50% 0 / contain no-repeat;
      }
    }

    &--truck {
      z-index: $z-index-truck;
      top: auto;
      bottom: 0;
      width: vw(124);
      max-width: 180px;
      animation-delay: -4s;
    }
  }

  &__castle {
    display: none;
  }

  &__content {
    position: relative;
    z-index: $z-index-content;
    margin: auto;
    padding-bottom: 20px;
    font-family: $family;
    font-size: $fix-font-base;
    font-weight: bold;
    line-height: 1.6;
    text-align: center;
  }

  & &__logo {
    display: inline-block;
    width: 128px;
    height: auto;
    margin-bottom: 40px;
  }

  &__list {
    display: flex;
    justify-content: center;

    gap: 0 20px;
  }

  &__button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 124px;
    height: 48px;
    margin-top: 25px;
    padding-right: 24px;
    overflow: hidden;
    border: 3px solid $black900;
    border-radius: 24px;
    background-color: $blue600;
    box-shadow: 3px 3px 0 $black900;
    font-size: $fix-font-base;
    font-weight: bold;
    text-align: center;
    text-indent: -999px;
    cursor: pointer;

    $root: &;

    &--on,
    &--off {
      > span {
        display: block;
        width: 31px;
        height: 14px;
        margin: 0 auto;
        background: image-url("top/landing/text_on_white.svg") center no-repeat;
        background-size: contain;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 10px;
        width: 25px;
        height: 25px;
        transform: translateY(-50%);
        background: image-url("top/landing/ico_sound_white.svg") center no-repeat;
        background-size: contain;
      }
    }

    &--off {
      > span {
        width: 35px;
        background-image: image-url("top/landing/text_off_white.svg");
      }

      &::after {
        width: 26px;
        background-image: image-url("top/landing/ico_mute_white.svg");
      }
    }
  }
}

@keyframes object-move {
  from {
    left: 0;
  }
  to {
    left: 100% + percent(300, $viewport-width);
  }
}

@keyframes loading-logo {
  from {
    transform: scale(0.86);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rathalos-buru {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-10px);
  }

  40% {
    transform: translateY(-10px);
    background-image: image-url("top/landing/img_rathalos_02.svg");
  }

  50% {
    background-image: image-url("top/landing/img_rathalos_01.svg");
  }

  100% {
    transform: translateY(0);
    background-image: image-url("top/landing/img_rathalos_01.svg");
  }
}

@keyframes object-flying {
  0% {
    transform: rotate(0);
  }

  20% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}
