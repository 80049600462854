/* ------------------------------
  header
------------------------------ */
.header {
  $this: &;
  position: fixed;
  z-index: z(header);
  top: 10px;
  right: 10px;
  // width: 85px;
  height: 100%;
  max-height: 45px;
  margin-left: auto;
  transition: all 0.3s ease-out;

  &.is-open {
    // width: 310px;
    max-height: calc(89vh - 20px);
    max-height: calc(100dvh - 20px);

    #top & {
      max-height: calc(89vh - 20px);
      max-height: calc(100dvh - 70px);
    }
  }

  &__container {
    display: flex;
    position: relative;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    border-radius: 23px;
    background-color: $blue600;
    clip-path: circle(115px 60px at 100% 0);
    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
  }

  &__box {
    display: flex;
    // position: absolute;
    z-index: z(header__box);
    // top: 0;
    // right: 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    // width: 85px;
    height: 45px;
    padding: 5px;
  }

  &__sound {
    margin-right: 5px;
    transition: $trans;

    .is-open & {
      transform: scale(0);
      // opacity: 0;
      pointer-events: none;
    }
  }

  &__sound-input {
    display: none;
  }

  &__sound-label {
    display: block;
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 25px;
    background-color: $blue600;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 22px;
      height: 22px;
      transform: translate(-50%, -50%);
      background: image-url("common/ico_sound_on.svg") 50% 50% / contain no-repeat;

      @at-root :checked + & {
        background-image: image-url("common/ico_sound_off.svg");
      }
    }
  }

  &__menu {
    position: relative;
    width: 35px;
    height: 35px;
    transition: all 0.3s ease-out;
    border-radius: 25px;
    background-color: $white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 15px;
      transform: translate(-50%, -50%);
      transition: transform 0.3s ease-out;
      background: image-url("common/ico_menu_open.svg")  50% 50% / contain no-repeat;
    }

    &.is-open {
      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
        background-image: image-url("common/ico_menu_close.svg");
      }
    }
  }

  &__nav {
    display: flex;
    position: relative;
    z-index: z(header__nav);
    flex: 1;
    flex-direction: column;
    width: 45px;
    min-height: 0%;
    margin-top: -45px;
    transition: all 0.4s ease-out;
    opacity: 0;

    .is-open & {
      width: 310px;
      opacity: 1;
    }
  }

  &__nav-inner {
    flex: 1;
    width: 310px;
    overflow-y: auto;
    background-color: $white;
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.2);
  }

  &__logo {
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 310px;
    padding: 16px 15px 14px;
    transition: 0.2s;
    border-radius: 30px 30px 0 0;
    opacity: 0;
    background-color: $blue600;
    text-align: center;

    .is-open & {
      opacity: 1;
    }

    a {
      display: block;
      transition: $trans;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &__logo-image {
    width: auto;
    height: 60px;
  }

  &__list {
    padding-top: 4px;
  }

  &__list-text {
    display: block;
    padding: 15px 20px 16px 57px;
    transition: all 0.3s ease-out;
    font-size: $fix-font-base;
    font-weight: bold;
    line-height: $line3;
    cursor: pointer;

    .is-current & {
      color: $blue600;
    }

    &:hover {
      background: $blue50;
      color: $blue600;
    }
  }

  &__list-item {
    position: relative;

    &--top {
      #top & {
        display: none;
      }
    }

    &:not(:last-child) {
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: image-url("common/bg_line_blue.svg") left bottom repeat-x;
      }
    }
  }

  &__list-title {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 16px;
      left: 30px;
      width: 12px;
      height: 18px;
      background: image-url("common/ico_pin_blue.svg") left top no-repeat;

      #{$this}__list-item--top & {
        top: 18px;
        left: 27px;
        width: 15px;
        height: 15px;
        background-image: image-url("common/ico_back_blue.svg");
      }
    }
  }

  &__list-box {
    display: none;
    padding-bottom: 18px;

    #{$this}__list-item.is-open & {
      display: block;
    }
  }

  &__list-sub {
    margin: 0 #{20}px 0 #{57 - 25}px; //list-linkのpad
    line-height: 1.5;
  }

  &__list-sub-item {
    padding: 0;
  }

  &__list-link {
    display: block;
    padding: 8px 10px 8px 25px;
    transition: all 0.3s ease-out;
    border-radius: 19px;
    color: #707070;
    font-size: $fix-font-small;
    font-weight: bold;
    cursor: pointer;

    .is-current & {
      color: $blue600;
    }

    &:hover {
      background: $blue50;
      color: $blue600;
    }
  }

  &__list-heading {
    display: flex;
    align-items: center;
    margin: 10px 40px;
    transition: all 0.3s ease-out;

    &::after {
      content: "";
      flex: 1 1 auto;
      height: 2px;
      background: image-url("common/bg_line_blue.svg") left bottom repeat-x;
    }

    &:hover {
      color: $blue600;
    }
  }

  &__list-heading-text {
    display: inline-block;
    position: relative;
    z-index: z(header-menu);
    padding-right: 9px;
    background-color: $white;
    font-size: $font-small;
    font-weight: bold;
  }

  &__bottom {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 310px;
    padding: 20px 8px 25px;
    text-align: center;
  }

  &__lang {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    border: 2px solid $white;
    border-radius: 50px;
    background-color: $blue600;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 8px;
      width: 15px;
      height: 15px;
      transform: translateY(-50%);
      background: image-url("common/ico_angle_down_white.svg") right center no-repeat;
    }
  }

  &__lang-input {
    height: 31px;
    padding: 0 25px 0 10px;
    border: 0;
    border-radius: 50px;
    background-color: $blue600;
    color: $white;
    font-size: $fix-font-small;
    font-weight: bold;
    text-align: center;
    text-align-last: center;
    cursor: pointer;
    appearance: none;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5px;
  }

  &__links-item {
    margin: 10px 7px 0;
  }

  &__links-link {
    display: block;
    color: $white;
    font-size: $fix-font-xsmall;
    line-height: $line2;

    &:hover {
      color: $white;
    }
  }
}

.header-overlay {
  visibility: hidden;
  position: fixed;
  z-index: z(header-overlay);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: all 0.3s ease-out;
  opacity: 0;
  background-color: rgba($black900, 0.5);

  &.is-open {
    visibility: visible;
    opacity: 1;
  }
}

/* header-back-button
---------------------------------------------------------- */
.header-back {
  display: flex;
  position: fixed;
  z-index: z(header-back-button);
  top: 10px;
  left: 10px;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease-out;
  border-radius: 60px;
  background-color: $blue600;

  &__button {
    display: block;
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 18px;
    background-color: $blue600;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 23px;
      height: 23px;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      background: image-url("common/ico_arrow_right_white.svg") center / contain  no-repeat;
    }
  }
}
