/* modal-introduction
---------------------------------------------------------- */
.modal-introduction * {
  box-sizing: border-box;
}

.modal-introduction {
  display: none;
  position: fixed;
  z-index: $modal;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: rgba($color: $black900, $alpha: 0.65);

  &__close {
    position: absolute;
    z-index: $modal-close;
    top: -10px;
    right: -10px;
    width: 46px;
    height: 46px;
    border: 4px solid $black900;
    border-radius: 50px;
    background-color: $blue600;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 15px;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      background: image-url("common/ico_close_white.svg") center no-repeat;
      background-size: 100%;
    }
  }

  &__content {
    position: absolute;
    top: 40px;
    left: 50%;
    width: calc(100% - 40px);
    height: 461px;
    transform: translateX(-50%);
    transition: all 0.3s ease-out;
    opacity: 0;

    &.is-show {
      opacity: 1;
    }
  }

  &__inner {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    border: 3px solid $black900;
    border-radius: 18px;
    background-color: $white;

    &.is-active {
      visibility: visible;
    }
  }

  &__top {
    padding: 29px 22px 110px;
  }

  &__bottom {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    left: -1px;
    width: calc(100% + 2px);
    padding: 18px 20px;
    border-radius: 0 0 14px 14px;
    background-color: $blue600;
  }

  &__title {
    display: flex;
    justify-content: center;
    color: $blue600;
    font-size: 24px;
    font-weight: $bold;
    line-height: $line4;
    text-align: center;

    img {
      height: 24px;
    }
  }

  &__text {
    margin-top: 10px;
    font-size: $fix-font-small;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;

    ._d-sm-none {
      display: none !important;
    }

    span {
      color: $red900;
    }
  }

  &__figure {
    margin: 12px auto 0;
    overflow: hidden;
    text-align: center;

    &-image {
      border-radius: 10px;

      &._d-sm-none {
        display: none !important;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    height: 44px;
    margin: 0 auto;
    padding: 0 15px;
    border: 3px solid $black900;
    border-radius: 20px;
    background-color: $white;
    box-shadow: 3px 3px 0 $black900;
    font-size: $fix-font-small;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    &--yellow {
      min-width: 180px;
      background-color: $yellow500;
    }
  }

  &__list {
    display: flex;
    position: absolute;
    z-index: $modal-pagination;
    right: 0;
    bottom: 99px;
    left: 0;
    justify-content: center;

    &--invisible {
      visibility: hidden;
    }

    &-item {
      margin: 0 5px;
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      padding-bottom: 0.5px;
      transition: all 0.3s ease-out;
      border-radius: 20px;
      background-color: $color-davy-grey;

      &.is-active {
        background-color: $blue600;
      }
    }
  }

  &__character {
    position: absolute;
    z-index: $modal-character;

    &-image {
      width: 100%;
    }

    &--01 {
      bottom: vw(-453);
      left: vw(-23);
      width: vw(254);
    }

    &--02 {
      right: vw(-30);
      bottom: vw(-400);
      width: vw(218);
    }

    &--03 {
      bottom: vw(-660);
      left: vw(-10);
      width: vw(160);
    }

    &--04 {
      right: vw(-35);
      bottom: vw(-685);
      width: vw(285);
    }

    &--05 {
      bottom: vw(-452);
      left: vw(-28);
      width: vw(247);
    }

    &--06 {
      right: vw(-35);
      bottom: vw(-404);
      width: vw(180);
    }

    &--07 {
      bottom: vw(-290);
      left: vw(-50);
      width: vw(240);
    }

    &--08 {
      right: vw(-20);
      bottom: vw(-270);
      width: vw(220);
    }
  }

  &__comment-box {
    width: 90%;
    margin: 15px auto 0;
    text-align: center;
  }

  &__comment-box-text {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    color: $blue600;
    font-size: $fix-font-small;
    font-weight: $bold;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -5px;
      left: -20px;
      width: 14px;
      height: 23px;
      background: image-url("common/img_yellow_deco.png") 100% / contain no-repeat;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: -5px;
      right: -20px;
      width: 14px;
      height: 23px;
      transform: scaleX(-1);
      background: image-url("common/img_yellow_deco.png") 100% / contain no-repeat;
    }
  }
}

/* modal-pin
---------------------------------------------------------- */
.modal-pin {
  visibility: hidden;
  position: fixed;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  transition: all 0.3s ease-out;
  opacity: 0;
  background-color: rgba($color: $black900, $alpha: 0.65);

  $root: &;

  &.is-show {
    visibility: visible;
    z-index: $modal;
    opacity: 1;
  }

  @media screen and (max-device-width: 896px) and (orientation : landscape) {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__close {
    position: absolute;
    z-index: $modal-close;
    top: -10px;
    right: -10px;
    width: 46px;
    height: 46px;
    border: 4px solid $black900;
    border-radius: 50px;
    background-color: $blue600;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 15px;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      background: image-url("common/ico_close_white.svg") center no-repeat;
      background-size: 100%;
    }
  }

  &__content {
    position: absolute;
    top: 97px;
    left: 50%;
    width: calc(100% - 40px);
    transform: translateX(-50%);
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    border: 3px solid $black900;
    border-radius: 18px;
    background-color: $white;
  }

  &__top {
    padding: 29px 22px 50px;
  }

  &__bottom {
    // position: absolute;
    // right: 0;
    // bottom: 0;
    // left: -1px;
    // width: calc(100% + 2px);
    padding: 18px 20px;
    border-radius: 0 0 14px 14px;
    background-color: $blue600;
  }

  &__title {
    display: flex;
    justify-content: center;
  }

  &__lead {
    margin-top: 10px;
    font-size: $fix-font-small;
    font-weight: bold;
    line-height: 1.65;
    text-align: center;
  }

  &__text {
    margin-top: 20px;
    font-size: $fix-font-small;
    font-weight: bold;
    line-height: 1.55;
    text-align: center;
  }

  &__list-button {
    display: flex;
    justify-content: center;

    &-item {
      &:nth-child(n+2) {
        margin-left: 17px;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 44px;
    margin: 0 auto;
    border: 3px solid $black900;
    border-radius: 20px;
    background-color: $white;
    box-shadow: 3px 3px 0 $black900;
    font-size: $fix-font-small;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    &--yellow {
      background-color: $yellow500;
    }
  }

  &__character {
    position: absolute;
    z-index: $modal-character;
  }

  &--museum {
    #{$root}__content {
      height: 282px;
    }

    #{$root}__title {
      height: 24px;
    }

    #{$root}__character {
      top: 100%;
      left: 50%;
      width: 270px;
      margin-left: -146px;

      img {
        width: 100%;
      }
    }
  }

  &--elections {
    #{$root}__content {
      height: 336px;
    }

    #{$root}__title {
      height: 62px;
    }

    #{$root}__character {
      top: calc(100% + 7px);
      left: 50%;
      width: 324px;
      margin-left: -131px;

      img {
        width: 100%;
      }
    }
  }
}
