/* ------------------------------
  form
------------------------------ */
.form-main {
  padding-bottom: rem(30);
}

.form {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  background: image-url("common/bg_form.png") 50% 0 / 76px 76px;

  &__inner {
    padding: 35px 20px 40px;
  }

  &__section {
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__heading01 {
    font-size: 20px;
    font-weight: $bold;
    line-height: $line3;
    text-align: center;
  }

  &__heading02 {
    font-size: $fix-font-base;
    font-weight: $bold;
    line-height: $line3;
    text-align: center;
  }

  &__text {
    margin-top: 25px;
    font-size: $fix-font-small;
    line-height: $line4;
    text-align: center;
  }
}

.form-title {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
  padding-top: 10px;
  color: $white;
  font-weight: $bold;
  text-align: center;

  img {
    width: auto;
    height: 11px;
  }

  span {
    display: block;
    margin-top: 5px;
    font-size: $fix-font-xsmall;
  }

  //タイトル青背景
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    width: 280px;
    height: 100%;
    transform: translateX(-50%);
    background: image-url("common/bg_form_title_sp.png") no-repeat center center / contain;
  }
}

.form-table-wrapper {
  margin-top: 25px;

  &:first-child {
    margin-top: 0;
  }
}

.form-table {
  $t: &;
  width: 100%;
  margin-top: 40px;

  &:first-child {
    margin-top: 0;
  }

  &__title {
    display: block;
    position: relative;
    padding-top: 25px;
    font-size: $fix-font-large;
    font-weight: $bold;
    line-height: $line4;
    text-align: left;

    #{$t} tr:first-child & {
      padding-top: 0;
    }

    span {
      font-size: $fix-font-small;
    }
  }

  &__data {
    display: block;
    padding-top: 12px;
    font-size: $fix-font-small;
    line-height: $line4;
  }

  &__note {
    display: block;
    margin-top: 10px;
    font-size: $fix-font-xsmall;
  }
}

//エラーメッセージ
.form-error-text {
  margin-top: 10px;
  color: $red900;
  font-size: $fix-font-small;
  font-weight: $bold;
}

//必須タグ
.form-required {
  display: inline-block;
  margin: 5px 0 0 10px;
  padding: 0 8px;
  border-radius: 5px;
  background: $indigo300;
  color: $white;
  font-size: $fix-font-xsmall;
  font-weight: $bold;
  line-height: $line6;
  vertical-align: top;
}

// 選択肢横並び
.form-select-list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px 0 0 -12px;

  &__item {
    margin: 10px 0 0 12px;
  }
}

//利用規約
.form-consent {
  margin-top: 30px;

  &__box {
    overflow: hidden;
    border: 2px solid $black900;
    border-radius: 15px;
    background: $white;
  }

  &__inner {
    padding: 20px;
  }

  &__title {
    padding: 18px 20px;
    background: $yellow500;
    font-size: $fix-font-small;
    font-weight: $bold;
    line-height: $line4;
    text-align: center;
  }

  &__link {
    color: $blue600;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__check {
    margin-top: 25px;
    font-size: $fix-font-base;
    text-align: center;
  }
}

.form-message-box {
  margin-top: 20px;
  padding: 25px 20px;
  border-radius: 10px;
  background: $white;
  box-shadow: 3px 3px 10px rgba($black900, 0.1);
  font-size: $fix-font-small;

  &--far {
    margin-top: 35px;
  }

  &:first-child {
    margin-top: 0;
  }
}

//青点付きリスト
.form-dot-list {
  &__item {
    position: relative;
    margin-top: 10px;
    padding-left: 11px;
    font-size: $fix-font-small;
    line-height: $line4;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 6px;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $blue600;
    }
  }
}

//横並びフィールド
.form-field {
  display: flex;
  align-items: center;
  gap: 10px;
}
