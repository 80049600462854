/* ------------------------------
  input
------------------------------ */
//テキスト
.input-text {
  max-width: 100%;
  height: 40px;

  .form-field & {
    flex: 1;
  }

  &__input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    border: 1px solid $black900;
    border-radius: 5px;
    background: $white;
    box-shadow: 0 0 10px rgba($black900, 0.1);
    font-size: $fix-font-small;
    line-height: normal;

    &:focus {
      outline: 0;
      color: $blue600;
    }

    &::placeholder {
      color: $black300;
    }

    &:disabled {
      border-color: $black400;
      background: $black100;
    }

    //エラー時
    &.is-error {
      border-color: $red900;
      background: $red50;
    }
  }
}

//セレクトボックス
.input-select {
  display: inline-block;
  position: relative;
  min-width: 230px;
  border-radius: 5px;
  background: $white;
  line-height: 1;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 12px;
    width: 12px;
    height: 8px;
    transform: translateY(-50%);
    background: image-url("/common/ico_angle_down_blue.svg") no-repeat center / contain;
    pointer-events: none;
  }

  &__select {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 38px;
    padding: 0 24px 0 15px;
    overflow: hidden;
    border: 1px solid $black900;
    border-radius: 5px;
    outline: 0;
    background: $white;
    box-shadow: 0 0 10px rgba($black900, 0.1);
    color: $black900;
    font-size: $fix-font-small;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &:focus {
      color: $blue600;
    }

    &:disabled {
      border-color: $black400;
      background: $black100;
    }

    //エラー時
    &.is-error {
      border-color: $red900;
      background: $red50;
    }
  }
}

//ラジオボタン
.input-radio {
  $t: &;

  &__input {
    position: absolute;
    opacity: 0;
  }

  &__label {
    display: block;
    position: relative;
    padding-left: 25px;
    font-size: $fix-font-small;
    cursor: pointer;

    #{$t}__input:disabled + & {
      color: $black400;
      pointer-events: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 1px;
      left: 0;
      box-sizing: border-box;
      width: 19px;
      height: 19px;
      border: 1px solid $black900;
      border-radius: 50%;
      background: $white;

      #{$t}__input:disabled + & {
        border-color: $black400;
      }

      //エラー時
      #{$t}__input.is-error + & {
        border-color: $red900;
        background: $red50;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 5px;
      left: 4px;
      width: 11px;
      height: 11px;
      transform: scale(0);
      transition: $trans;
      border-radius: 50%;
      background: #004bb1;

      #{$t}__input:checked + & {
        transform: scale(1);
      }
    }
  }
}

//チェックボックス
.input-check {
  $t: &;
  display: inline-block;
  position: relative;

  &__input {
    position: absolute;
    opacity: 0;
  }

  &__label {
    display: block;
    position: relative;
    padding-left: 30px;
    font-size: $fix-font-small;
    cursor: pointer;

    #{$t}__input:disabled + & {
      color: $black400;
      pointer-events: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 3px;
      left: 0;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      border: 1px solid $black900;
      border-radius: 4px;
      background: $white;

      #{$t}__input:disabled + & {
        border-color: $black400;
      }

      //エラー時
      #{$t}__input.is-error + & {
        border-color: $red900;
        background: $red50;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 9px;
      left: 3px;
      width: 0;
      height: 10px;
      transition: $trans;
      background: image-url("common/ico_check_blue.svg") no-repeat 0 0 / auto 100%;

      #{$t}__input:checked + & {
        width: 14px;
      }
    }
  }
}

//テキストエリア
.input-textarea {
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }

  &__input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    min-height: 106px;
    padding: 10px 15px;
    border: 1px solid $black900;
    border-radius: 5px;
    background: $white;
    box-shadow: 0 0 10px rgba($black900, 0.1);
    font-size: $fix-font-small;

    &--large {
      height: 160px;
    }

    &:focus {
      outline: 0;
      color: $blue600;
    }

    &::placeholder {
      color: $black300;
    }

    &:disabled {
      border-color: $black400;
      background: $black100;
    }

    //エラー時
    &.is-error {
      border-color: $red900;
      background: $red50;
    }
  }
}
