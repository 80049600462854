/* ------------------------------
  共通レイアウト
------------------------------ */
html {
  overflow: hidden;
  background: $black900;
  font-size: vw(10);
  // font-size: 0.73vw;

  &.remodal-is-locked {
    pointer-events: none;
  }

  &.is-loaded { //共通ローダー完了
    overflow-y: auto;
  }

  &.is-fixed {
    height: 100vh;
    overflow: hidden;
  }

  &.is-locked {
    // overflow-y: scroll;
    position: fixed;
    left: 0;
    width: 100%;

    &::after {
      z-index: $base-locked;
      opacity: 1;
    }
  }
}

body {
  box-sizing: border-box;
  // min-width: rem(1000);
  color: $black900;
  font: #{$font-base} / 1.75 $family;
  font-weight: $normal;
  letter-spacing: 0.05rem;
  overflow-wrap: break-word;
  word-break: break-word;
  image-rendering: auto;

  &:lang(zh-CN),
  &:lang(zh-TW) {
    font-family: $family-chinese;
  }

  .is-fixed & {
    position: fixed;
    width: 100%;
  }
}

h1,
h2,
h3,
p,
ul,
ol,
dl,
dd {
  margin: 0;
  padding: 0;
}

a {
  transition: $trans;
  color: $black900;
  text-decoration: none;

  &:hover {
    color: $blue600;
  }
}

button {
  transition: $trans;
  background-color: $white;

  &:hover {
    cursor: pointer;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

li {
  list-style: none;
}

input,
button,
textarea {
  border: 0;
  font-family: $family;
  font-size: $font-base;
  -webkit-appearance: none;
  appearance: none;
}

.only-sp {
  display: none !important;
}
