// z-index
$modal: 1000;
$modal-character: 1;
$modal-pagination: 2;
$modal-close: 3;
$balloon: 2;
$landing: 1000;
$landing-cloud: 1;
$landing-sonson: 2;
$landing-dragon: 2;
$landing-airplane: 3;
$landing-truck: 1;
$landing-content: 10;
$info-notice: 10;
$info-notice-close: 1001;
