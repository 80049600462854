/* modal-introduction
---------------------------------------------------------- */
.modal-introduction * {
  box-sizing: border-box;
}

.modal-introduction {
  display: none;
  position: fixed;
  z-index: $modal;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: rgba($color: $black900, $alpha: 0.65);

  &__close {
    position: absolute;
    z-index: $modal-close;
    top: -13px;
    right: -14px;
    width: 58px;
    height: 58px;
    border: 4px solid $black900;
    border-radius: 50px;
    background-color: $blue600;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      background: image-url("common/ico_close_white.svg") center no-repeat;
      background-size: 100%;
    }

    &:hover {
      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 570px;
    width: 40%;
    height: 518px;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-out;
    opacity: 0;

    &.is-show {
      opacity: 1;
    }
  }

  &__inner {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    border: 5px solid $black900;
    border-radius: 25px;
    background-color: $white;

    &.is-active {
      visibility: visible;
    }
  }

  &__top {
    padding: 40px 45px;
  }

  &__bottom {
    display: flex;
    // position: absolute;
    // right: 0;
    // bottom: 0;
    // left: -1px;
    // width: calc(100% + 2px);
    padding: 20px 20px 19px;
    border-radius: 0 0 19px 19px;
    background-color: $blue600;
  }

  &__title {
    display: flex;
    justify-content: center;
    color: $blue600;
    font-size: $font-heading02;
    font-weight: $bold;
    line-height: $line4;
    text-align: center;

    img {
      height: 32px;
    }
  }

  &__text {
    margin-top: 19px;
    font-size: $font-base;
    font-weight: bold;
    line-height: 1.65;
    text-align: center;

    ._d-md-none {
      display: none !important;
    }

    span {
      color: $red900;
    }
  }

  &__figure {
    margin: 10px auto 0;
    overflow: hidden;
    text-align: center;

    &-image {
      border-radius: 10px;

      &._d-md-none {
        display: none !important;
      }
    }
  }

  &__button {
    @include hoverOpacity;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    height: 40px;
    margin: 0 auto;
    padding: 0 15px;
    border: 3px solid $black900;
    border-radius: 20px;
    background-color: $white;
    box-shadow: 3px 3px 0 $black900;
    font-size: $font-base;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    &--yellow {
      min-width: 190px;
      background-color: $yellow500;

      &:hover {
        color: $black900;
      }
    }
  }

  &__list {
    display: flex;
    position: absolute;
    z-index: $modal-pagination;
    right: 0;
    bottom: 106px;
    left: 0;
    justify-content: center;

    &--invisible {
      visibility: hidden;
    }

    &-item {
      margin: 0 5px;
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      padding-bottom: 0.5px;
      transition: all 0.3s ease-out;
      border-radius: 20px;
      background-color: $color-davy-grey;

      &.is-active {
        background-color: $blue600;
      }

      &:hover {
        background-color: $blue600;
      }
    }
  }

  &__character {
    position: absolute;
    z-index: $modal-character;

    &--01 {
      top: -15px;
      left: -306px;
      max-width: 326px;
      max-height: 564px;
      min-width: 326px;
      min-height: 564px;
    }

    &--02 {
      right: -253px;
      bottom: -15px;
      max-width: 266px;
      max-height: 432px;
      min-width: 266px;
      min-height: 432px;
    }

    &--03 {
      top: 80px;
      left: -255px;
      max-width: 230px;
      max-height: 550px;
      min-width: 230px;
      min-height: 550px;
    }

    &--04 {
      top: 45px;
      right: -287px;
      max-width: 385px;
      max-height: 610px;
      min-width: 385px;
      min-height: 610px;
    }

    &--05 {
      top: 80px;
      left: -427px;
      max-width: 512px;
      max-height: 856px;
      min-width: 512px;
      min-height: 856px;
    }

    &--06 {
      top: 120px;
      right: -403px;
      max-width: 375px;
      max-height: 734px;
      min-width: 375px;
      min-height: 734px;
    }

    &--07 {
      bottom: -10px;
      left: -268px;
      width: 300px;
      height: 450px;
    }

    &--08 {
      right: -268px;
      bottom: -10px;
      width: 300px;
      height: 450px;
    }
  }

  &__character-image {
    width: 100%;
    height: 100%;
  }

  &__comment-box {
    width: 90%;
    margin: 23px auto 0;
    text-align: center;
  }

  &__comment-box-text {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    color: $blue600;
    font-weight: $bold;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -5px;
      left: -20px;
      width: 14px;
      height: 23px;
      background: image-url("common/img_yellow_deco.png") 100% / contain no-repeat;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: -5px;
      right: -20px;
      width: 14px;
      height: 23px;
      transform: scaleX(-1);
      background: image-url("common/img_yellow_deco.png") 100% / contain no-repeat;
    }
  }
}

/* modal-pin
---------------------------------------------------------- */
.modal-pin {
  visibility: hidden;
  position: fixed;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  transition: all 0.3s ease-out;
  opacity: 0;
  background-color: rgba($color: $black900, $alpha: 0.65);

  $root: &;

  &.is-show {
    visibility: visible;
    z-index: $modal;
    opacity: 1;
  }

  @media screen and (max-device-width: 896px) and (orientation : landscape) {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__close {
    position: absolute;
    z-index: $modal-close;
    top: -15px;
    right: -14px;
    width: 58px;
    height: 58px;
    border: 4px solid $black900;
    border-radius: 50px;
    background-color: $blue600;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      background: image-url("common/ico_close_white.svg") center no-repeat;
      background-size: 100%;
    }

    &:hover {
      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 440px;
  }

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 440px;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    border: 5px solid $black900;
    border-radius: 25px;
    background-color: $white;
  }

  &__top {
    padding: 46px 20px 120px;
  }

  &__bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    left: -1px;
    width: calc(100% + 2px);
    padding: 20px 20px 19px;
    border-radius: 0 0 19px 19px;
    background-color: $blue600;
  }

  &__title {
    display: flex;
    justify-content: center;
  }

  &__lead {
    margin-top: 12px;
    font-size: $font-base;
    font-weight: bold;
    line-height: 1.65;
    text-align: center;
  }

  &__text {
    margin-top: 19px;
    font-size: $font-base;
    font-weight: bold;
    line-height: 1.65;
    text-align: center;
  }

  &__list-button {
    display: flex;
    justify-content: center;

    &-item {
      &:nth-child(n+2) {
        margin-left: 18px;
      }
    }
  }

  &__button {
    @include hoverOpacity;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    margin: 0 auto;
    border: 3px solid $black900;
    border-radius: 20px;
    background-color: $white;
    box-shadow: 3px 3px 0 $black900;
    font-size: $font-base;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    &--yellow {
      background-color: $yellow500;
    }
  }

  &__character {
    position: absolute;
    z-index: $modal-character;
  }

  &--museum {
    #{$root}__content {
      height: 336px;
      transform: translate(-76.5%, -50%);
    }

    #{$root}__title {
      height: 32px;
    }

    #{$root}__character {
      top: -126px;
      right: -275px;
      max-width: 234px;
      max-height: 575px;
      min-width: 234px;
      min-height: 575px;
    }
  }

  &--elections {
    #{$root}__content {
      height: 390px;
      transform: translate(-83.5%, -50%);
    }

    #{$root}__title {
      height: 78px;
    }

    #{$root}__character {
      top: -133px;
      right: -390px;
      max-width: 350px;
      max-height: 615px;
      min-width: 350px;
      min-height: 615px;
    }
  }
}
