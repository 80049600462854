html {
  overflow: hidden;
  background: $black900;
  font-size: vw(10);
  scrollbar-width: none; // table-scroll firefox対応
  -ms-overflow-style: none; // table-scroll ie対応
  ::-webkit-scrollbar {
    display: none;
  }

  &.is-loaded { //共通ローダー完了
    overflow-y: auto;
  }

  &.is-locked {
    // overflow-y: scroll;
    position: fixed;
    left: 0;
    width: 100%;

    &::after {
      z-index: $base-locked;
      opacity: 1;
    }
  }
}
body {
  color: $black900;
  font: #{$font-base} / 1.75 $family;
  -webkit-font-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-weight: normal;
  letter-spacing: 0.05rem;
  image-rendering: auto;

  &:lang(zh-CN),
  &:lang(zh-TW) {
    font-family: $family-chinese;
  }
}
img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  image-rendering: auto;
}

a {
  transition: $trans;
  color: $black900;
  text-decoration: none;
}

th,
td {
  word-break: break-all;
}

input,
button,
select,
textarea {
  font-family: $family;
  -webkit-appearance: none;
  appearance: none;

  &:focus {
    outline: none;
  }
}

button {
  transition: $trans;
  background-color: $white;

  &:hover {
    cursor: pointer;
  }
}

::placeholder {
  color: $black500;
}

.only-pc {
  display: none !important;
}
