@mixin max($w:1099px) {
  @media screen and (max-width: $w) {
    & {
      @content;
    }
  }
}

@mixin min($w:1600px) {
  @media screen and (min-width: $w) {
    & {
      @content;
    }
  }
}

//セレクタ置換
//使い方
//@include replace("置換したいセレクター", "置換後のセレクター") {
  //スタイルを記述
//}
@mixin replace ($original, $replacement) {
  @at-root #{selector-replace(&, $original, $replacement)} {
    @content
  }
}


//透過ホバー
//使い方
//@include hoverOpacity() {
//スタイルを記述
//}
@mixin hoverOpacity($opacity: 0.7) {
  opacity: 1;
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: $opacity;
    text-decoration: none;
  }
}
