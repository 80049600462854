//フォントファミリー
$family: "Helvetica Neue",
Arial, "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
$family-chinese: "SourceSans3";

//フォントサイズ
$font-xsmall: 12px;
$font-small: 14px;
$font-base: 16px;
$font-large: 18px;

//見出しフォントサイズ
$font-heading01: 36px;
$font-heading02: 30px;
$font-heading03: 26px;
$font-heading04: 20px;

//フォントウェイト
$normal: 500;
$bold: 700;

//行間
$line1: 1;
$line2: 1.25;
$line3: 1.34;
$line4: 1.5;
$line5: 1.67;
$line6: 1.75;
$line7: 2;

//デザインベース
$base-width: 1366;

//最小・最大幅
$min-width: 1024px;
$max-width: 1400px;

//アニメーション
$trans: 0.3s ease;
