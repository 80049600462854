$viewport-width: 390;
$z-index-map-blur: 1;
$z-index-pop-effect: 4;
$z-index-all-characters: 3;
$z-index-bar-text: 2;
$z-index-bg-sky: 1;
$z-index-building: 2;
$z-index-rathalos: 3;
$z-index-makaimura: 3;
$z-index-vega: 5;
$z-index-sonson: 7;
$z-index-cloud-01: 6;
$z-index-cloud-02: 4;
$z-index-balloon: 7;

.opening-top {
  display: none;
  position: fixed;
  z-index: $modal;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;

  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);

    img {
      display: block;
      width: 100%;
      max-width: none;
      height: auto;
      max-height: none;
    }
  }

  &__scene-02 {
    opacity: 0;
  }

  &__pop-effect {
    position: absolute;
    z-index: $z-index-pop-effect;
    top: 50%;
    left: 50%;
    width: percent(163, $viewport-width);
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  &__all-characters {
    position: absolute;
    z-index: $z-index-all-characters;
    top: 50%;
    left: 50%;
    width: percent(400, $viewport-width);
    opacity: 0;
  }

  &__map-blur {
    position: absolute;
    z-index: $z-index-map-blur;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: image-url("top/opening/bg_map_blur.jpg") center no-repeat;
    background-size: cover;
  }

  &__thanks-bar {
    position: absolute;
    z-index: $z-index-bar-text;
    top: 50%;
    left: 50%;
    width: percent(408, $viewport-width);
    transform: translate(-50%, 216.84%);
    opacity: 0;
  }

  &__thanks-bar-bg {
    --clip: percent(390, $viewport-width);

    clip-path: inset(0 var(--clip) 0 0);
  }

  &__thanks-bar-text {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: percent(307, 408);
    transform: translate(-50%, -50%);
  }

  &__bg-sky {
    position: absolute;
    z-index: $z-index-bg-sky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: image-url("top/opening/bg_sky_sp.png") center no-repeat;
    background-size: cover;
  }

  &__building {
    position: absolute;
    z-index: $z-index-building;
    top: percent(5, $viewport-width);
    left: 50%;
    width: percent(500, $viewport-width);
    transform: translateX(-48%);
  }

  &__rathalos {
    position: absolute;
    z-index: $z-index-rathalos;
    top: 0;
    left: 0;
    width: percent(230, $viewport-width);
  }

  &__sonson {
    position: absolute;
    z-index: $z-index-sonson;
    top: 0;
    right: 0;
    width: percent(253, $viewport-width);
  }

  &__vega {
    position: absolute;
    z-index: $z-index-vega;
    right: 0;
    bottom: 0;
    width: percent(170, $viewport-width);
  }

  &__makaimura {
    position: absolute;
    z-index: $z-index-makaimura;
    bottom: 0;
    left: 0;
    width: percent(100, $viewport-width);
  }

  &__balloon {
    position: absolute;
    z-index: $z-index-balloon;
    right: 0;
    bottom: 0;
    width: percent(548, $viewport-width);
  }

  &__cloud-01 {
    position: absolute;
    z-index: $z-index-cloud-01;
    top: 0;
    left: 0;
    width: percent(380, $viewport-width);
  }

  &__cloud-02 {
    position: absolute;
    z-index: $z-index-cloud-01;
    top: 0;
    left: 0;
    width: percent(380, $viewport-width);
  }

  &__cloud-03 {
    position: absolute;
    z-index: $z-index-cloud-02;
    top: 0;
    right: 0;
    width: percent(316, $viewport-width);
  }

  &__cloud-04 {
    position: absolute;
    z-index: $z-index-cloud-01;
    top: 0;
    right: 0;
    width: percent(550, $viewport-width);
  }

  &__cloud-05 {
    position: absolute;
    z-index: $z-index-cloud-01;
    bottom: 0;
    left: 0;
    width: percent(427, $viewport-width);
  }

  &__cloud-06 {
    position: absolute;
    z-index: $z-index-cloud-01;
    right: 0;
    bottom: 0;
    width: percent(549, $viewport-width);
  }

  &__cloud-07 {
    position: absolute;
    z-index: $z-index-cloud-02;
    bottom: 0;
    left: 0;
    width: percent(578, $viewport-width);
  }
}
