/* ------------------------------
  remodal-custom
------------------------------ */
.remodal-overlay {
  z-index: z(remodal-overlay);
  background: rgba($black900, 0.65);

  //ミュージアム タイトル選択
  &.remodal-museum-title {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: rgba($black900, 0.3);
  }

  //ミュージアム年齢確認
  &.remodal-museum-age {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: rgba($black900, 0.85);
  }
}

.remodal-wrapper {
  z-index: z(remodal-wrapper);
  padding: 20px 0 0;
  pointer-events: auto; //is-locked用

  //ミュージアム タイトル選択
  &.remodal-museum-title {
    padding: 0;
    overflow: hidden;
    text-align: right;
  }

  //会長の部屋 名言
  &.remodal-room-famous-quote {
    padding: 0;
    overflow: hidden;
    text-align: left;
  }

  //ゲーム詳細 キーバインド
  &.remodal-game-key-bind {
    padding: 0;
    overflow: hidden;
  }
}

.remodal {
  z-index: z(remodal);
  width: auto;
  margin-bottom: 20px;
  padding: 50px 70px;
  border: $black900 5px solid;
  border-radius: 20px;

  //ボタンフロー付き
  &--flow {
    padding: 0;
  }

  //ゲーム詳細 キーバインド
  &.remodal-game-key-bind {
    display: inline-flex;
    flex-direction: column;
    width: 95%;
    max-width: none;
    max-height: 95vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  //ゲーム詳細 カートリッジ
  &.remodal-game-cartridge {
    max-width: 90%;
    padding: rem(110) rem(130) rem(100);
  }

  //ミュージアム タイトル選択
  &.remodal-museum-title {
    // display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
    border-radius: 0;
    background: none;
    pointer-events: none;

    &.remodal-is-closing {
      animation-name: remodal-closing-museum-keyframes;
    }
  }

  //会長の部屋 名言
  &.remodal-room-famous-quote {
    display: fixed;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    max-width: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
    border-radius: 0;
    background: $black900;
  }

  // 超選挙中間発表
  &.remodal-middle-results {
    max-width: 766px;
    padding: 0;
    border: 0;
    border-radius: 10px;
  }

  &__body-flow {
    padding: 50px 70px;
  }
}

.remodal-close {
  display: flex;
  z-index: 2;
  top: -19px;
  right: -19px;
  left: auto;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  transition: $trans;
  border: $black900 4px solid;
  border-radius: 50px;
  background: $blue600;
  pointer-events: auto;

  //ミュージアム タイトル選択・詳細
  .remodal-museum-title & {
    top: 15px;
    right: 15px;
  }

  //会長の部屋 名言
  .remodal-room-famous-quote & {
    top: rem(30);
    right: rem(30);
  }

  // 超選挙中間発表
  .remodal-middle-results & {
    top: -25px;
    right: -25px;
  }

  &::before {
    content: "";
    position: static;
    width: 20px;
    height: 20px;
    background: image-url("common/ico_close_white.svg") 0 0 / 100% 100% no-repeat;
  }

  &:hover {
    transform: scale(1.1);
    border-color: $white;
    background-color: $black900;
  }
}

//モーダル タイトル
.remodal-title {
  font-size: $font-large;
  font-weight: $bold;
  text-align: center;

  img {
    height: 28px;
  }

  &__sub {
    display: block;
    margin-top: 6px;
  }
}

.remodal-text {
  $t: &;
  margin-top: 30px;
  font-size: $font-large;
  font-weight: $bold;
  text-align: center;

  &+& {
    margin-top: 10px;
  }

  &--error {
    color: $red900;
  }
}

.remodal-button-box {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 30px 0;
  background: $blue600;

  &:last-child {
    border-radius: 0 0 15px 15px;
  }

  &__item {
    margin-right: 40px;

    &--close {
      margin-right: 20px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@keyframes remodal-closing-museum-keyframes {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.05);
    opacity: 0;
    filter: blur(0);
  }
}
