$viewport-width: 1366;
$z-index-cloud: 1;
$z-index-object: 2;
$z-index-rathalos: 2;
$z-index-airplane: 3;
$z-index-truck: 1;
$z-index-content: 10;

.loading-top {
  display: flex;
  position: fixed;
  z-index: $modal + 1;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $logo-blue;

  &__logo {
    display: block;
    position: absolute;
    width: 180px;
    height: auto;
    animation: loading-logo 0.3s linear 1s forwards;
    opacity: 0;
  }
}

.landing-top * {
  box-sizing: border-box;
}

.landing-top {
  visibility: hidden;
  position: fixed;
  z-index: $modal;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #feeb53;

  $this: &;

  &--ready {
    visibility: visible;
  }

  &__wrapper {
    display: flex;
    position: relative;
    height: 100%;
    min-height: 530px;
    overflow: hidden;
    background-image: image-url("top/landing/bg_landing_pc.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;

    img {
      display: block;
      width: 100%;
      max-width: none;
      height: auto;
      max-height: none;
    }
  }

  &__cloud {
    position: absolute;
    z-index: $z-index-cloud;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    animation: object-move 103.88s linear infinite;

    &--first {
      width: vw(309);
      margin-top: percent(-23, $viewport-width);
      animation-delay: -34.11s;

      @media screen and (min-width: 1366px) {
        width: percent(309, $viewport-width);
      }
    }

    &--second {
      width: vw(304);
      margin-top: percent(107, $viewport-width);
      animation-delay: -80.82s;

      @media screen and (min-width: 1366px) {
        width: percent(303, $viewport-width);
      }
    }

    &--third {
      width: vw(380);
      margin-top: percent(107, $viewport-width);
      animation-delay: -18.35s;

      @media screen and (min-width: 1366px) {
        width: percent(380, $viewport-width);
      }
    }
  }

  &__object {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    animation: object-move 21.6s linear infinite;

    &--first {
      z-index: $z-index-object;
      width: vw(160);
      margin-top: percent(48, $viewport-width);
      animation-delay: -2.88s;

      @media screen and (min-width: 1366px) {
        width: percent(160, $viewport-width);
      }

    }

    &--second {
      z-index: $z-index-object;
      top: initial;
      bottom: 0;
      width: vw(220);
      margin-bottom: percent(216, $viewport-width);
      animation-delay: -4.38s;
      animation-direction: reverse;


      @media screen and (min-width: 1366px) {
        width: percent(200, $viewport-width);
      }

      &::before {
        content: "";
        display: block;
        padding-top: 58.11%;
        animation: object-flying 5s linear infinite;
        background: image-url("top/landing/img_kobun.svg") 50% 0 / contain no-repeat;
      }
    }

    &--rathalos {
      z-index: $z-index-rathalos;
      top: initial;
      bottom: 0;
      width: vw(234);
      margin-bottom: percent(216, $viewport-width);
      animation-delay: -4.38s;
      animation-direction: reverse;

      @media screen and (min-width: 1366px) {
        width: percent(234, $viewport-width);
      }

      &::before {
        content: "";
        display: block;
        padding-top: 58.11%;
        animation: rathalos-buru 5s linear infinite;
        background: image-url("top/landing/img_rathalos_01.svg") 50% 0 / contain no-repeat;
      }

    }

    &--truck {
      z-index: $z-index-truck;
      top: auto;
      bottom: 0;
      width: vw(180);
      animation-delay: -8.64s;

      @media screen and (min-width: 1366px) {
        width: percent(180, $viewport-width);
      }
    }
  }

  &__castle {
    position: absolute;
    z-index: 2;

    &--first {
      bottom: -3px;
      left: percent(264, $viewport-width);
      width: vw(170);

      @media screen and (min-width: 1366px) {
        width: percent(170, $viewport-width);
      }
    }

    &--second {
      bottom: -8px;
      left: percent(1056, $viewport-width);
      width: vw(179);

      @media screen and (min-width: 1366px) {
        width: percent(179, $viewport-width);
      }
    }
  }

  &__content {
    position: relative;
    z-index: $z-index-content;
    margin: auto;
    padding-bottom: 20px;
    font-family: $family;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.6;
    text-align: center;
  }

  & &__logo {
    display: inline-block;
    width: 128px;
    margin-bottom: clamp(20px, calc(65 / 768 * 100vh), 65px);
  }

  &__list {
    display: flex;
    justify-content: center;

    gap: 0 20px;
  }

  &__button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 48px;
    margin-top: 30px;
    overflow: hidden;
    border: 3px solid $black900;
    border-radius: 24px;
    background-color: $blue600;
    box-shadow: 3px 3px 0 $black900;
    font-size: $font-base;
    font-weight: bold;
    text-align: center;
    text-indent: -999px;
    cursor: pointer;

    $root: &;

    &--on,
    &--off {
      > span {
        display: block;
        width: 31px;
        height: 14px;
        margin: 0 auto;
        background: image-url("top/landing/text_on_white.svg") center no-repeat;
        background-size: contain;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 12px;
        width: 25px;
        height: 25px;
        transform: translateY(-50%);
        background: image-url("top/landing/ico_sound_white.svg") center no-repeat;
        background-size: contain;
      }
    }

    &--off {
      > span {
        width: 35px;
        background-image: image-url("top/landing/text_off_white.svg");
      }

      &::after {
        width: 26px;
        background-image: image-url("top/landing/ico_mute_white.svg");
      }
    }

    &:hover {
      background-color: #ffe133;

      &#{$root}--on {
        > span {
          background-image: image-url("top/landing/text_on_black.svg");
        }

        &::after {
          background-image: image-url("top/landing/ico_sound_black.svg");
        }
      }

      &#{$root}--off {
        > span {
          background-image: image-url("top/landing/text_off_black.svg");
        }

        &::after {
          background-image: image-url("top/landing/ico_mute_black.svg");
        }
      }
    }
  }
}

@keyframes object-move {
  from {
    left: 0;
  }

  to {
    left: 100% + percent(400, $viewport-width);
  }
}

@keyframes loading-logo {
  from {
    transform: scale(0.86);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rathalos-buru {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-20px);
  }

  40% {
    transform: translateY(-20px);
    background-image: image-url("top/landing/img_rathalos_02.svg");
  }

  50% {
    background-image: image-url("top/landing/img_rathalos_01.svg");
  }

  100% {
    transform: translateY(0);
    background-image: image-url("top/landing/img_rathalos_01.svg");
  }
}

@keyframes object-flying {
  0% {
    transform: rotate(0);
  }

  20% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}
