/* ------------------------------
  button
------------------------------ */
//通常ボタン（影あり線あり）
@mixin btn-base($w: 260px, $pad: 7px 15px) {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  min-width: $w;
  padding: $pad;
  transition: 0.2s;
  border-width: 3px;
  border-style: solid;
  border-radius: 40px; //3行まで丸く
  box-shadow: 3px 3px 0 $black900;
  font-weight: $bold;
  line-height: $line2;
  text-align: center;

  &.is-disabled {
    border-color: $black400 !important;
    opacity: 0.8;
    background-color: $black100 !important;
    box-shadow: 3px 3px 0 $black400;
    color: $black400;
    pointer-events: none;
  }

  &:hover {
    transform: translate(3px, 3px);
    box-shadow: none;
  }
}

//ミニボタン（影無し線のみ）
@mixin btn-base-mini($pad: 5px 15px) {
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: $pad;
  transition: 0.2s;
  border-width: 2px;
  border-style: solid;
  border-radius: 40px; //3行まで丸く
  font-size: $font-small;
  font-weight: $bold;
  line-height: $line2;
  text-align: center;

  &.is-disabled {
    border-color: $black400 !important;
    opacity: 0.8;
    background-color: $black100 !important;
    color: $black400;
    pointer-events: none;
  }

  &:hover {
    transform: scale(1.05);
  }
}

//基本（白・黒線）
.button {
  @include btn-base();
  border-color: $black900;
  background: $white;
  color: $black900;

  &:hover {
    color: $black900;
  }

  .button-box-many__button & {
    min-width: auto;
  }
}

//第1ボタン（黄・黒線）
.button-primary {
  @include btn-base();
  border-color: $black900;
  background: $yellow500;
  color: $black900;

  &:hover {
    color: $black900;
  }
}

//第1ボタン play（黄・黒線）
.button-play {
  @include btn-base($w: 0, $pad: rem(9) rem(15) rem(8));
  border-color: $black900;
  background: $yellow500;
  color: $black900;

  &:hover {
    color: $black900;
  }

  img {
    width: auto;
    height: rem(22);
  }
}



//第2ボタン（黒・白線）
.button-secondary {
  @include btn-base();
  border-color: $white;
  background: $black900;
  color: $white;

  &:hover {
    color: $white;
  }
}

//第3ボタン（青・黒線）
.button-tertiary {
  @include btn-base();
  border-color: $black900;
  background: $blue600;
  color: $white;

  &:hover {
    color: $white;
  }
}

//第4ボタン サブ(グレー・黒線)
.button-sub {
  @include btn-base();
  border-color: $black900;
  background: $black100;
  color: $black900;

  &:hover {
    color: $black900;
  }
}

//第5ボタン ミニ(白・黒線)
.button-mini {
  @include btn-base-mini();
  border-color: $black900;
  background: $white;
  color: $black900;

  &:hover {
    color: $black900;
  }
}

//サウンドボタン（第3ボタン）
.button-sound {
  $t: &;
  @include btn-base();
  min-width: 150px;
  border-color: $black900;
  background: $blue600;
  color: $white;

  span {
    flex: 1 1 auto;
    height: 15px;
    margin-left: 15px;
    color: transparent;
  }

  &::after {
    content: "";
    width: 26px;
    height: 25px;
  }

  &--on {
    &::after {
      background: image-url("common/ico_sound_on.svg") 50% 50% / auto 100% no-repeat;
    }

    span {
      background: image-url("common/text_on_white.svg") 50% 50% / auto 100% no-repeat;
    }
  }

  &--off {
    &::after {
      background: image-url("common/ico_sound_off.svg") 50% 50% / auto 100% no-repeat;
    }

    span {
      background: image-url("common/text_off_white.svg") 50% 50% / auto 100% no-repeat;
    }
  }

  &:hover {
    color: $white;
  }
}

// ----- オプション ----- //
//ボタン小
.button-small {
  min-width: 150px;
}

// ----- Button BOX ----- //
.button-box {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  &:first-child {
    margin-top: 0;
  }

  &--far {
    margin-top: 70px;
  }

  &__button {
    display: flex; //2行以上で揃う
    margin-left: 24px;

    &:first-child {
      margin-left: 0;
    }
  }
}

//ボタン3つ以上
.button-box-many {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  &__button {
    width: calc((100% - 48px) / 3);
    margin-left: 24px;

    &:first-child {
      margin-left: 0;
    }
  }
}
