/*--------------------------------------------------
 ※※※※※※※※※※※※※※※※※※※※※※※※
 TOPページ
 このファイルはsassでコンパイルされています
 以下の内容を変更したい場合は構成するscssファイルを
 ご確認ください
 ※※※※※※※※※※※※※※※※※※※※※※※※
--------------------------------------------------*/

//↓↓↓ 以下サンプル。モノサス様作業で、必要ファイルのみインポートしてください ↓↓↓

// rails では下の行を消す
@import "image-url";
@import "font-url";

//import
@font-face {
  font-family: "SourceSans3";
  src: font-url("/fonts/SourceSans3-VF.woff2") format("woff2");
  font-display: swap;
  font-weight: 100 900;
}
@import "reset";
@import "remodal";
@import "remodal-default-theme";

// definitions
@import "variables", "variables_top";


@media screen and (max-width: 767.9px) {
  /*　画面サイズが767.9pxまではここを読み込む　*/
  // definitions
  @import "sp/function", "sp/variables",  "sp/mixin";

  //layout
  @import
  "sp/base",
  "sp/button",
  "sp/header",
  "sp/footer",
  "sp/remodal-custom",
  "sp/form",
  "sp/input";

  //components
  @import
  "sp/top",
  "sp/share",
  "sp/modal",
  "sp/info",
  "sp/landing-top",
  "sp/opening-top";
}

@media (orientation: landscape) {
  /*　デバイス横向き時はここを読み込む　*/
  // definitions
  @import "sp/function", "sp/variables";

  //layout
  @import "sp/landscape";
}

@media (orientation: portrait) {
  /*　デバイス縦向き時はここを読み込む　*/
  .landscape {
    display: none;
  }
}

@media screen and (min-width: 768px), print {
  /*　画面サイズが768pxからはここを読み込む　*/
  // definitions
  @import "pc/variables", "pc/function", "pc/mixin";

  //layout
  @import
  "pc/base",
  "pc/button",
  "pc/header",
  "pc/footer",
  "pc/remodal-custom",
  "pc/form",
  "pc/input";

  //components
  @import
  "pc/top",
  "pc/share",
  "pc/modal",
  "pc/info",
  "pc/landing-top",
  "pc/opening-top";
}

@media screen and (min-width: 1600px) {
  html {
    font-size: 12px;
  }
}
