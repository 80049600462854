/* ------------------------------
  share
------------------------------ */
//コピー完了（headerに設置）
.share-copy-message {
  display: none;
  position: fixed;
  z-index: z(share-copy-message);
  top: 50%;
  left: 0;
  width: 100%;
  padding: rem(40) 0;
  transform: translateY(-50%);
  background: rgba($black900, 0.7);
  color: $white;
  font-size: 20px;
  font-weight: $bold;
  text-align: center;
}

//シェアリンク　タイトル
.share-title {
  font-size: $font-large;
  font-weight: $bold;
  text-align: center;

  img {
    height: 28px;
  }

  &__sub {
    display: block;
    margin-top: 6px;
  }
}

// snsリンクリスト
.share-list {
  $t: &;
  display: flex;
  justify-content: center;
  margin-top: 35px;

  &:first-child {
    margin-top: 0;
  }

  &--pc-small {
    margin-top: 10px;
  }

  .news-detail-share & {
    flex-direction: column;
  }

  .news-detail-footer-share & {
    margin-top: 0;
  }

  &__item {
    min-width: 84px;
    margin-right: 25px;

    #{$t}--pc-small & {
      min-width: auto;
      margin-right: 20px;
    }

    .news-detail-share & {
      margin: 0 0 15px;
    }

    &:last-child {
      margin: 0;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    transition: $trans;
    background: transparent;

    img {
      width: 76px;
      height: 76px;

      #{$t}--pc-small & {
        width: 40px;
        height: 40px;
      }
    }

    &:hover {
      transform: scale(1.08);
      color: $black900;

      #{$t}--pc-small & {
        transform: scale(1.2);
      }
    }
  }

  &__name {
    display: block;
    margin-top: 8px;
    font-size: $font-small;
    font-weight: $bold;
    text-align: center;
  }
}

// Twitterアカウント導線
.share-twitter-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  &__text {
    box-sizing: border-box;
    width: 100%;
    padding: 25px 60px 45px;
    border-radius: 5px;
    background: $indigo50;
    font-weight: $bold;
    text-align: center;
  }

  &__button {
    margin-top: -30px;
  }
}


// TOP系 固定シェアボタン
.share-button-box {
  display: flex;
  position: fixed;
  z-index: z(share-button);
  right: 20px;
  bottom: 20px;
}

.share-button {
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &__button {
    @include btn-base($w: 120px);
    border-color: $blue600;
    background: $white;
    box-shadow: 3px 3px 0 $blue600;
    color: $blue600;

    &:hover {
      color: $blue600;
    }

    &::before {
      content: "";
      width: 18px;
      height: 18px;
      margin-right: 8px;
      background: image-url("common/ico_share_blue.svg") center / contain no-repeat;
    }

    &--twitter {
      &::before {
        width: 20px;
        height: 20px;
        background-image: image-url("common/logo_twitter02.svg");
      }
    }
  }
}

//現在のページをシェア
.share-current-page {
  margin-top: 35px;

  &:first-child {
    margin-top: 0;
  }

  &__textarea {
    margin-top: 30px;
  }
}
